import { Button, Card, Image, Input, Text } from "@geist-ui/core";
import { Form, Link, useNavigate } from "@remix-run/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch, initSystems, setGuest, setUser } from "~/appSlice";
import { login } from "~/auth.client";
import AuthLayout from "~/layout/AuthLayout";

export default function Login() {
  const [clientError, setClientError] = useState<string | null>(null);
  const navigate = useNavigate();
  const dispatch = useDispatch<AppDispatch>();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const form = event.currentTarget;
    const formData = new FormData(form);

    try {
      const user = await login(
        formData.get("email") as string,
        formData.get("password") as string
      );

      dispatch(setUser(user.uid));
      dispatch(setGuest(false));
      dispatch(initSystems(undefined));
    } catch (error: any) {
      setClientError(error.message);
    }
  };

  return (
    <AuthLayout>
      <Card width="400px">
        <Image style={{ textAlign: "center", padding: 20 }} src="logo.png" />
        <Form method="post" onSubmit={handleSubmit} style={{ display: "flex", flexDirection: "column" }}>
          <Input name="email" htmlType="email" clearable autoComplete="email" placeholder="Email" required width="100%" marginBottom="20px" crossOrigin={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          <Input.Password crossOrigin="*" name="password" clearable placeholder="Password" required width="100%" marginBottom="20px" onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined} />
          <Button htmlType="submit" type="secondary" placeholder={undefined} onPointerEnterCapture={undefined} onPointerLeaveCapture={undefined}>Log in</Button>
        </Form>
        {clientError && <Text type="error" marginTop="20px">{clientError}</Text>}
        <Text p>Don't have an account? <Link to="/signup">Sign up</Link></Text>
        <Link to="/forgot-password"><Text p>Forgot your password?</Text></Link>
        <Link to="/app" onClick={() => { dispatch(setGuest(true)) }} prefetch="render"><Text p>Continue as guest</Text></Link>
      </Card>
    </AuthLayout>
  );
}